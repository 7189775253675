import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	Typography,
	Container,
	TextField,
	Button,
	Grid,
	Paper,
	RadioGroup,
	FormControlLabel,
	Radio,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import * as Yup from "yup";
import Plot from "./Plot";

const Schema = Yup.object().shape({
	d_wt: Yup.number().required("Required!"),
	coll_age_gp: Yup.number().required("Required!"),
	abscd34_5pre: Yup.number().required("Required!"),
	bld_vol5: Yup.number().required("Required!"),
	dsex: Yup.number().required("Please select an option"),
});

const ModelInputForm = ({ num }) => {
	const formik = useFormik({
		initialValues: {
			d_wt: null,
			coll_age_gp: null,
			abscd34_5pre: null,
			dsex: "1",
			bld_vol5: null,
			threshold: 200,
		},
		validationSchema: Schema,
		onSubmit: async (values) => {
			setIsLoading(true);
			predictions[num] = null;
			setPredictions(predictions);
			let submission = {};
			let coll_age_gp = 1;
			for (let key in values) {
				submission[key] = [parseFloat(values[key])];

				if (key === "coll_age_gp") {
					let v = values[key];
					if (18 <= v && v <= 29) {
						submission["coll_age_gp"] = [1];
					} else if (30 <= v && v <= 39) {
						submission["coll_age_gp"] = [2];
					} else if (40 <= v && v <= 49) {
						submission["coll_age_gp"] = [3];
					} else if (v >= 50) {
						submission["coll_age_gp"] = [4];
					} else {
						submission["coll_age_gp"] = [1];
					}
				}
			}
			if (num === 0) {
				delete submission.threshold;

				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_ENDPOINT}/model${1}`,
					submission
				);
				const response2 = await axios.post(
					`${process.env.REACT_APP_BACKEND_ENDPOINT}/model${2}`,
					submission
				);

				predictions[0] = response.data.data;
				predictions[1] = response2.data.data;
			} else {
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_ENDPOINT}/model${3}`,
					submission
				);
				predictions[2] = response.data.data.score;
				setThreshold(response.data.data.optimal);
			}
			setPredictions(predictions);
			setIsLoading(false);
		},
	});

	const [predictions, setPredictions] = useState([null, null]);
	const [isLoading, setIsLoading] = useState(false);
	const [threshold, setThreshold] = useState(200);
	return (
		<>
			<Grid sm={12} style={{ paddingBottom: "2rem" }}>
				<Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
					Model {num === 0 ? "1 and Model 2" : "3"}
				</Typography>
				<Paper elevation={3} style={{ padding: 20, marginRight: 10 }}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={3}>
							<Grid item md={6}>
								<TextField
									label="Donor Weight (Kg)"
									variant="outlined"
									fullWidth
									margin="normal"
									id="d_wt"
									name="d_wt"
									onChange={formik.handleChange}
									InputLabelProps={{ shrink: true }}
									value={formik.values.d_wt}
									error={formik.touched.d_wt && Boolean(formik.errors.d_wt)}
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									label="Donor Age (Years)"
									variant="outlined"
									fullWidth
									margin="normal"
									id="coll_age_gp"
									name="coll_age_gp"
									onChange={formik.handleChange}
									InputLabelProps={{ shrink: true }}
									value={formik.values.coll_age_gp}
									error={
										formik.touched.coll_age_gp &&
										Boolean(formik.errors.coll_age_gp)
									}
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									label={
										<React.Fragment>
											{"Pre-apheresis CD34+ (x10"}
											<Typography variant="subtitle2" component="sup">
												6
											</Typography>
											{"/L)"}
										</React.Fragment>
									}
									variant="outlined"
									fullWidth
									margin="normal"
									id="abscd34_5pre"
									name="abscd34_5pre"
									InputLabelProps={{ shrink: true }}
									onChange={formik.handleChange}
									value={formik.values.abscd34_5pre}
									error={
										formik.touched.abscd34_5pre &&
										Boolean(formik.errors.abscd34_5pre)
									}
								/>
							</Grid>
							<Grid item md={6}>
								<TextField
									label="Blood Volume (L)"
									variant="outlined"
									fullWidth
									margin="normal"
									id="bld_vol5"
									name="bld_vol5"
									InputLabelProps={{ shrink: true }}
									onChange={formik.handleChange}
									value={formik.values.bld_vol5}
									error={
										formik.touched.bld_vol5 && Boolean(formik.errors.bld_vol5)
									}
								/>
							</Grid>
							<Grid item md={6} style={{ paddingTop: "1.5rem" }}>
								<Typography variant={"body2"}>Donor Sex</Typography>
								<RadioGroup
									aria-label="dsex"
									name="dsex"
									value={formik.values.dsex}
									onChange={formik.handleChange}
									error={formik.touched.dsex && Boolean(formik.errors.dsex)}
									row
								>
									<FormControlLabel
										value={"1"}
										control={<Radio color="primary" />}
										label="Male"
									/>
									<FormControlLabel
										value={"2"}
										control={<Radio color="primary" />}
										label="Female"
									/>
								</RadioGroup>
							</Grid>
							{num === 1 && (
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<InputLabel id="threshold-label" shrink>
											<React.Fragment>
												{"Desired CD34+ Cell Yield (x10"}
												<Typography variant="subtitle2" component="sup">
													6
												</Typography>
												{"/L)"}
											</React.Fragment>
										</InputLabel>
										<Select
											labelId="threshold-label"
											id="threshold"
											name="threshold"
											value={formik.values.threshold}
											label="threshold"
											onChange={formik.handleChange}
										>
											<MenuItem value={200}>200</MenuItem>
											<MenuItem value={400}>400</MenuItem>
											<MenuItem value={600}>600</MenuItem>
											<MenuItem value={800}>800</MenuItem>
											<MenuItem value={1000}>1000</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={12} style={{ paddingInline: "2rem" }}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									type="submit"
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
					{!isLoading && num === 1 && predictions[2] && (
						<>
							<Typography style={{ marginTop: "2rem" }}>
								Model predicts yield
								<span inline style={{ fontWeight: "bold" }}>
									{threshold < parseFloat(predictions[2])
										? " over "
										: " under "}
								</span>
								the desired target
							</Typography>
						</>
					)}
					{num < 1 && predictions[num] && (
						<>
							<Typography style={{ marginTop: "1rem" }}>
								Model 1 Total CD34+ (x10
								<Typography variant="subtitle2" component="sup">
									6
								</Typography>
								/L): {predictions[0]}
							</Typography>
							<Typography>
								Model 2 Total CD34+(x10
								<Typography variant="subtitle2" component="sup">
									6
								</Typography>
								/L): {predictions[1]}
							</Typography>
						</>
					)}
					{isLoading && (
						<Grid
							style={{ marginTop: "1rem" }}
							sm={12}
							container
							direction="row"
							justifyContent="center"
						>
							<CircularProgress />
						</Grid>
					)}
				</Paper>
			</Grid>
			{predictions[2] && (
				<Plot threshold={threshold} prediction={predictions[2]} />
			)}
		</>
	);
};

const App = () => {
	return (
		<div>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6">
						Total CD34+ Yield Prediction Models
					</Typography>
				</Toolbar>
			</AppBar>

			<Container style={{ marginTop: 20 }}>
				<Grid container direction="row" justifyContent="space-between">
					<ModelInputForm num={0} />
					<ModelInputForm num={1} />
				</Grid>
			</Container>
		</div>
	);
};

export default App;
