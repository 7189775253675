import React, { useEffect } from "react";
import Plotly from "plotly.js-dist-min";

const Plot = ({ threshold, prediction }) => {
	// Demo data
	const x = Array.from({ length: 100 }, (_, i) => i);

	// Function to calculate normal distribution
	const normalDistribution = (x, mean, stdDev) => {
		const expTerm = Math.exp(-((x - mean) ** 2) / (2 * stdDev ** 2));
		return (1 / (stdDev * Math.sqrt(2 * Math.PI))) * expTerm;
	};

	const mean = 50;
	const stdDev = 15;
	const y = x.map((value) => normalDistribution(value, mean, stdDev));

	const new_prediction_point = prediction * 100;
	const new_prediction_y = normalDistribution(
		new_prediction_point,
		mean,
		stdDev
	);

	threshold *= 100;
	useEffect(() => {
		const belowTargetIndices = [];
		x.forEach((value) =>
			value < threshold ? belowTargetIndices.push(value) : null
		);
		const aboveTargetIndices = [];
		x.forEach((value) =>
			value >= threshold ? aboveTargetIndices.push(value) : null
		);

		const y1 = y.slice(0, belowTargetIndices.length);
		const y2 = y.slice(belowTargetIndices.length);

		const trace1 = {
			x: belowTargetIndices,
			y: y1,
			type: "scatter",
			mode: "lines",
			line: { color: "black", width: 3 },
			fill: "tozeroy",
			fillcolor: "pink",
			name: "Below Target",
		};

		const trace2 = {
			x: aboveTargetIndices,
			y: y2,
			type: "scatter",
			mode: "lines",
			line: { color: "black", width: 3 },
			fill: "tozeroy",
			fillcolor: "turquoise",
			name: "Above Target",
		};

		const trace3 = {
			x: [threshold, threshold],
			y: [Math.min(...y), Math.max(...y)],
			type: "scatter",
			mode: "lines",
			line: { color: "black", width: 2, dash: "dash" },
			name: "Threshold",
		};

		const trace4 = {
			x: [new_prediction_point],
			y: [new_prediction_y],
			type: "scatter",
			mode: "markers",
			marker: { color: "blue", size: 20 },
			name: "Prediction",
		};

		const layout = {
			xaxis: { range: [0, 105] },
			yaxis: { visible: false },
			legend: { x: 1, y: 1, traceorder: "normal" },
		};

		Plotly.newPlot("plotly-chart", [trace1, trace2, trace3, trace4], layout);
	}, [x, y, threshold, new_prediction_point, new_prediction_y]);

	return (
		<div style={{display:"flex", flexDirection: "row", justifyContent:"center", alignItems:"center"}}>
			<div id="plotly-chart" />
		</div>
	);
};

export default Plot;
